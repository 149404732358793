<template>
    <div class="main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">视频管理</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="uploadVideoDia()" size="mini">上传视频</el-button>
            </div>
        </div>
        <div class="sort-item">
            <div class="sort-info">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
                    全选 <span class="selected-num-title">(已选择{{selectedNum}}个)</span>
                </el-checkbox>
            </div>
            <div class="del" @click="deletePro">
                <i class="iconfont">&#xe638;</i>
                <span style="padding-left: 2px;">删除</span>
            </div>
        </div>
        <div class="video-list-content">
            <el-scrollbar class="video-list">
                <div class="video-item" v-if="videoList.length > 0">
                    <div class="top-animate" v-for="(videoItem,index) in videoList" :key="`videoList_${index}`">
                        <el-checkbox-group v-model="checkedLists" @change="selectPro">
                            <el-checkbox :label="videoItem.id"></el-checkbox>
                        </el-checkbox-group>
                        <div class="video-img">
                            <video :src="videoItem.video_path"></video>
                        </div>
                        <div class="info-title">
                            <p class="text">{{videoItem.title}}</p>
                            <div class="video-content">
                                <div class="video-content-left">
                                    <p class="fl-title">分类：{{videoItem.classification_name}}</p>
                                    <p class="time-title">时间：{{videoItem.create_time}}</p>
                                </div>
                                <div class="video-content-right" @click="videoPlay(videoItem.video_path)">
                                    <img src="../../assets/img/image/video-play.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else style="display: flex;justify-content: center;align-items: center;height: 600px;">暂无数据</div>
            </el-scrollbar>
            <el-pagination
                    class="pages-center"  style="margin-bottom: 10px;"
                    :current-page="videoPages.currentPageNum"
                    :page-size="videoPages.eachPageNum"
                    v-if="videoList.length > 0"
                    layout="prev, pager, next, jumper"
                    :total="videoPages.total"
                    @current-change="videoCurrentChange">
            </el-pagination>
        </div>
        <!--S 上传视频弹窗-->
        <el-dialog  :title="videoTitleType" :visible.sync="closeVideoDialog" width="30%" @close="resetForm()">
            <el-form :model="helpVideoForm" ref="helpVideoForm" :rules="rules" label-position="center">
                <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
                    <el-input v-model="helpVideoForm.title" autocomplete="off" placeholder="请输入标题" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="选择分类" :label-width="formLabelWidth" prop="classification_id">
                    <el-cascader style="width: 80%;"
                            v-model="helpVideoForm.classification_id"
                            :options="helpClassify"
                            :props="ClassifyProps"
                            :show-all-levels="false"
                            @change="handleClassify"></el-cascader>
                </el-form-item>
                <el-form-item label="上传视频" :label-width="formLabelWidth" prop="videoSrc">
                    <el-button type="primary" v-model="helpVideoForm.videoSrc" class="upload-btn-video" size="medium">
                        <span>{{helpVideoForm.videoSrc ? '重新上传' : '上传视频'}}</span>
                        <input type="file" accept="video/*"  @change="changeVideo($event)">
                    </el-button>
                    <div class="video-show" v-show="helpVideoForm.videoUrl.length>0">
                        <video :src="helpVideoForm.videoUrl" controls="controls"></video>
                    </div>
                    <div class="video-request">
                        <span>1.支持常用视频格式，推荐使用mp4、webm</span>
                        <span>2.视频文件大小不超过20M，时长在15分钟以下</span>
                        <span>3.分辨率为720p(1280×720)及以上</span>
                    </div>
                </el-form-item>
                <el-form-item class="video-btn">
                    <el-button class="blue-btn" @click="onSubmit('helpVideoForm')">确定</el-button>
                    <el-button size="medium" @click="closeVideoDialog = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!--E 上传视频弹窗-->
    </div>
</template>

<script>
    export default {
        name: "HelpVideo",
        data(){
            return{
                //全选
                checkAll: false,
                // 已选
                selectedNum: 0,
                //全选状态
                isIndeterminate: false,
                checkedLists: [],
                videoIdList:[],
                //视频列表
                videoList:[],
                //添加视频弹窗
                closeVideoDialog:false,
                //添加视频表单
                helpVideoForm:{
                    id:'',
                    title:'',
                    classification_id:'',
                    videoFile: '',
                    videoFileSize: '',
                    videoUrl: '',
                    videoSrc: '',
                },
                //帮助的分类
                helpClassify: [],
                ClassifyProps: {
                    value: 'id',
                    label: 'name'
                },
                formLabelWidth: '80px',
                videoTitleType:'',
                //确定/保存
                distinguishBtn: '',
                // 表单验证
                rules:{
                    title: [
                        {required: true, message: '请输入标题', trigger: 'blur'},
                        {min: 1, max: 40, message: '长度在 1 到40个字符', trigger: 'blur'},
                    ],
                    classification_id: [
                        {required: true, message: '请选择分类', trigger: 'change'},
                    ],
                    videoSrc: [
                        {required: true, message: '请上传视频', trigger: 'blur'},
                    ],
                },
                //分页
                videoPages: {
                    currentPageNum: 1,
                    eachPageNum: 12,
                    total: 0
                },
            }
        },
        mounted() {
            this.getHelpClassify()
            this.getHelpVideoList()
        },
        methods:{
            //添加视频
            uploadVideoDia(){
                this.closeVideoDialog = !this.closeVideoDialog
                this.videoTitleType = '添加视频';
                this.distinguishBtn = 'add';
            },
            // 上传视频
            changeVideo(event){
                if (event.target.files.length === 0) return;
                if (event.target.files[0].size > 1024 * 1024 * 20) {
                    this.$message.warning('视频大小超过20M');
                    return;
                } else {
                    let fileList = event.target.files[0];
                    this.helpVideoForm.videoFile = fileList;
                    this.helpVideoForm.videoFileSize = (fileList.size / (1024 * 1024)).toFixed(2);
                    let url = '';
                    let reader = new FileReader();
                    reader.readAsDataURL(fileList);
                    let that = this;
                    reader.onload = function (e) {
                        url = this.result.substring(this.result.indexOf(',') + 1);
                        that.helpVideoForm.videoSrc = "data:videoSrc/*," + url;
                    }
                    event.target.value = '';
                    this.uploadVideo();
                }
            },
            uploadVideo() {
                let formData = new FormData();
                //本地上传
                if (this.helpVideoForm.videoFile) {
                    formData.append('file', this.helpVideoForm.videoFile);
                }
                this.$http.axiosPost(this.$api.help_uploadvideo,formData,(res) => {
                    if (res.code === 200) {
                        this.helpVideoForm.videoSrc = res.data.src;
                        this.helpVideoForm.videoUrl = res.data.url;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 确定
            onSubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('title', this.helpVideoForm.title);
                        formData.append('classification_id', this.helpVideoForm.classification_id);
                        formData.append('video_path', this.helpVideoForm.videoSrc);
                        this.$http.axiosPost(this.$api.help_video, formData, (res) => {
                            if (res.code === 200) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.closeVideoDialog = !this.closeVideoDialog;
                                        this.resetForm();
                                        this.getHelpVideoList();
                                    }
                                });
                            }else {
                                this.$message({
                                    type: "error",
                                    message: res.message,
                                    duration: 1000,
                                });
                            }
                        }, (err) => {
                            console.log(err);
                        })

                    }
                });
            },
            //获取帮助分类
            getHelpClassify() {
                this.$http.axiosGet(this.$api.helpClassification, (res) => {
                    if (res.code === 200) {
                        this.helpClassify = res.data.data;
                    }
                }, (err) => {
                    console.log(err)
                })
            },

            handleClassify(val) {
                if (val) {
                    this.helpVideoForm.classification_id = val[val.length - 1];
                }
            },
            // 获取视频列表
            getHelpVideoList(){
                this.videoIdList=[]
                let param = {
                    page: this.videoPages.currentPageNum,
                    pageSize: this.videoPages.eachPageNum,
                    paging: 1,
                }
                this.$http.axiosGetBy(this.$api.help_video, param,(res) => {
                    if (res.code === 200) {
                        this.videoList = res.data.data;
                        this.videoPages.total = res.data.total;
                        this.videoList.forEach((item) => {
                            this.videoIdList.push(item.id)
                        })
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 视频播放
            videoPlay(video_path){
                window.open(video_path)
            },
            // 全选
            handleCheckAllChange(val) {
                this.checkedLists = val ? this.videoIdList : [];
                this.isIndeterminate = false;
                this.selectedNum = this.checkedLists.length;
            },
            //单选
            selectPro(val){
                this.checkedLists = val
                let checkedCount = val.length;
                this.checkAll = checkedCount === this.videoList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.videoList.length;
                this.selectedNum = val.length;
            },
            // 删除
            deletePro(){
                let param = {
                    id: this.checkedLists
                }
                if (this.checkedLists.length === 0) {
                    this.$message({
                        type: "error",
                        message: '请选择视频',
                        duration: 1000
                    });
                    return;
                }
                this.$confirm('是否删除该视频，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'blue-btn',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosPost(this.$api.help_delete_video, param, res => {
                            if (res.code === 200) {
                                this.$message({
                                    type: "success",
                                    message: res.message,
                                    duration: 1000,
                                });
                                this.getHelpVideoList();
                                this.checkedLists = [];
                                this.selectedNum = 0;
                                this.isIndeterminate = false;
                                this.checkAll = false;
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.message,
                                    duration: 1000
                                });
                            }
                        },
                        err => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            //重置表单
            resetForm() {
                this.$refs.helpVideoForm.resetFields();
                for (let key in this.helpVideoForm) {
                    this.helpVideoForm[key] = "";
                }
            },
            //分页
            videoCurrentChange(val) {
                this.videoPages.currentPageNum = val;
                this.getHelpVideoList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .admin-top {
        align-items: center;
    }
    .sort-item{
        display: flex;
        align-items: center;
        padding: 0 20px 20px;
        box-sizing: border-box;
        .sort-info{
            .selected-num-title{
                width: 120px;
                display: inline-block;
            }
        }
        .del{
            cursor: pointer;
            &:hover{
                color: #f56c6c;
            }
        }
        ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #1E33E3;
            border-color: #1E33E3;
        }
        ::v-deep.el-checkbox__input.is-checked+.el-checkbox__label {
            color: #1E33E3;
        }
    }
    .video-list-content{
        display: flex;
        flex-direction: column;
        height:calc(100vh - 200px);
        padding-left: 20px;
        .video-list{
            height: 100%;
            ::v-deep.el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    height: 100%;
                }
            }
            .video-item{
                display: flex;
                flex-wrap: wrap;
                .top-animate{
                    width: calc(25% - 20px);
                    margin: 0 20px 15px 0;
                    position: relative;
                    overflow: hidden;
                    .video-img{
                        width: 100%;
                        height: 0;
                        padding-bottom: 66.67%;
                        position: relative;
                        overflow: hidden;
                        cursor: pointer;
                        background: #F1F2FF;
                        video{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 257px;
                            height: 140px;
                            /*max-width: 100%;*/
                            /*min-height: 140px;*/
                        }
                    }
                    a{
                        color: #333;
                    }
                    .info-title{
                        background: #F1F2FF;
                        padding: 0 13px 16px;
                        p{
                            padding: 10px 0 0;
                            margin: 0;
                        }
                        .text{
                            display:block;
                            max-width: 370px;
                            max-height: 39px;
                            font-size: 14px;
                            text-overflow: ellipsis;
                            -webkit-box-orient: vertical;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .fl-title,.time-title{
                            color: #666;
                            font-size: 12px;
                        }
                        .video-content{
                            display: flex;
                            align-items: center;
                            .video-content-left{
                                flex: 1;
                            }
                            .video-content-right{
                                width: 24px;
                                height: 24px;
                                display: inline-block;
                                cursor: pointer;
                                &:hover{
                                    transform: scale(1.2);
                                }
                                img{
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                    ::v-deep.el-checkbox-group{
                        position: absolute;
                        left: 188px;
                        top: 6px;
                        width: 20px;
                    }
                    ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
                        background-color: #1E33E3;
                        border-color: #1E33E3;
                    }
                }
            }
        }
    }
    .upload-btn-video {
        position: relative;
        padding: 7px 16px;
        background: #594FEE;
        border-color: #594FEE;
        cursor: pointer;
        &:hover {
            background: #2338E6;
            border-color: #2338E6;
        }
        input {
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .video-request {
        display: flex;
        flex-direction: column;
        color: #BCB9CB;
        font-size: 14px;
    }
    .video-show {
        margin: 0 0 10px;
        width: 300px;
        height: 150px;
        video {
            width: 100%;
            height: 100%;
        }
    }
    .video-btn{
        display: flex;
        justify-content: center;
    }
</style>